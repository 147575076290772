import React, { useEffect } from 'react'
import { FormGroup, TextField } from '@material-ui/core'
import { Email, Person } from '@material-ui/icons'
import * as styles from './contact-form.module.css'
import TextFieldWithIcon from './text-field-with-icon'

export default function ApplicationForm(): JSX.Element {

  const formReference = React.useRef<HTMLFormElement>()

  const [checking, setChecking] = React.useState(false)
  const [awaitingSubmit, setAwaitingSubmit] = React.useState(false)

  function onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    if (checking) {
      setAwaitingSubmit(true) // tslint:disable-line:no-expression-statement
      event.preventDefault() // tslint:disable-line:no-expression-statement
    }
  }

  // tslint:disable-next-line:no-expression-statement
  useEffect(() => {
    if (awaitingSubmit && !checking) {
      const submitButton: HTMLButtonElement = formReference.current!.querySelector('button[type="submit"]')! as any
      return submitButton.click()
    }
  }, [awaitingSubmit, checking])

  return (
    <form
      name="contact-us"
      className={styles.form}
      method="POST"
      action={"/contact-thank-you"}
      data-netlify="true"
      netlify-honeypot="bot-field"
      autoComplete="off"
      ref={formReference as any}
      onSubmit={onSubmit}
    >
      <input type="hidden" name="form-name" value="contact-us" />
      <p style={{ display: 'none' }}>
        <label>
          Don’t fill this out if you're human <input name="bot-field" />
        </label>
      </p>
      <FormGroup className={styles.formGroup}>
        <TextFieldWithIcon
          label='Name'
          name="name"
          variant="outlined"
          required
          startIcon={<Person />}
        />
      </FormGroup>
      <FormGroup className={styles.formGroup}>
        <TextFieldWithIcon
          type="email"
          label='Email'
          name="email"
          variant="outlined"
          required
          startIcon={<Email />}
        />
      </FormGroup>
      <div>
        <FormGroup className={styles.formGroup}>
          <TextField
            label='Message'
            name="message"
            variant="outlined"
            required
            multiline
            rows={5}
            InputProps={{
              inputProps: {
                'aria-label': 'Message',
              },
            }}
          />
        </FormGroup>
      </div>
      <FormGroup className={styles.formGroup}>
        <button type="submit" className={styles.submitButton}>
          Send
        </button>
      </FormGroup>
    </form>
  )
}
