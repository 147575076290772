import React from "react"
import { StandardLayout } from "../layout/layout"
import * as styles from './contact-us.module.css'
import * as colors from '../colors.module.css'
import {StaticImage} from 'gatsby-plugin-image'
import ApplicationForm from "../components/contact-form"

function HowToReachUs() {
  return (
    <div className={styles.howToReachUs}>
      <h3>
        <span>TO REACH US BY PHONE</span>
      </h3>
      <p>Call <a href="tel:+1 (734) 730-7378">+1 (734) 730-7378</a></p>
      <h3>
        <span>TO REACH US BY MAIL</span>
      </h3>
      <p>
        Donations and other mail can be sent to:<br/>
      </p>
      <p>
        Dream Sports Africa<br/>
        1150 4th Street Southwest, Unit 517<br/>
        Washington DC, 20024
      </p>
    </div>
  )
}

const ContactUs = () => {
  return (
    <StandardLayout
      title="Contact Us"
      subtitle="get in touch with our dream team"
      backgroundColor={colors.redBackground}
      titleColor={colors.navy}
      img={<StaticImage src="../images/contact_us.jpg" className={styles.donateImage} loading="eager" />}
      ogImageSrc="../images/contact_us.jpg"
      excludeDonateAndSignup
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <ApplicationForm />
          <HowToReachUs />
        </div>
      </div>
    </StandardLayout>
  )
}

export default ContactUs
